import { KeyValue } from '@angular/common';
import { HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { APP_CONFIG } from '../../../../config/app.server.config';
import { EventHistoryInterface } from '../../../shared/domain/models/event-history.interface';
import { HistoryInterface } from '../../../shared/domain/models/history.interface';
import { NoteDBInterface, NoteInterface } from '../../../shared/domain/models/note.interface';
import { CoreRepository } from '../../infrastructure/repositories/core.repository';
import { DateUtil } from '../utils/date/date.util';
import * as FileSaver from 'file-saver';

@Injectable({
    providedIn: 'root',
})

export class CoreService
{
    public static UPLOAD_PROGRESS = 1;
    public static RESPONSE_STATUS_SUCCESS = 200;
    validateExpression = this.coreRepository.validateExpression;

    constructor(private coreRepository: CoreRepository)
    {}

    static empty = (mixedVar) =>
        mixedVar === '' ||
        mixedVar === undefined ||
        mixedVar === 0 ||
        mixedVar === '0' ||
        mixedVar === null ||
        mixedVar === false ||
        (Array.isArray(mixedVar) && mixedVar.length === 0);

    static changeStatusHistory(history: HistoryInterface[], entityName: string): EventHistoryInterface[]
    {
        const result = [];
        for (const h of history) {
            result.push({
                eventName: 'STATUS_CHANGE',
                author: 'system',
                date: h.date,
                message: `${entityName} status changed${h?.from ? ' from ' + h.from: ' '} to ${h.to}`,
            });
        }
        return result;
    }

    static setupHistory(history: HistoryInterface[], fullEventHistory: EventHistoryInterface[], entityName: string): {
        history: HistoryInterface[];
        fullEventHistory: EventHistoryInterface[];
    }{
        if (!history?.length) {
            return {history, fullEventHistory};
        }
        fullEventHistory = [...fullEventHistory, ...CoreService.changeStatusHistory(history, entityName)];
        return {history, fullEventHistory};
    }

    static prepareNotes = (notes: NoteDBInterface[]): NoteInterface[] => notes?.map(({author, date, message}) => {
        const parsedDate = DateUtil.convertStringToDate(date?.date);
        return ({
            author,
            date: parsedDate,
            message
        });
    });

    isPrimitive(data: any): boolean
    {
        return typeof data !== 'object' || Array.isArray(data) && !data.length || !Array.isArray(data) && Object.keys(data || {})?.length === 0;
    }

    public originalOrder = (a: KeyValue<any, any>, b: KeyValue<any, any>): number => 0;

    public uploadFile = (file: File | Blob, folder: string, fileName?: string, useOriginalName?: boolean): Observable<any> => {
        if (!file || !folder) {
            return throwError('no file or folder');
        }
        let name = fileName;
        if (file instanceof File && !fileName) {
            name = file.name;
        }
        const formData = new FormData();
        formData.append('file', file, name);
        formData.append('folder', folder);
        if (useOriginalName) {
            formData.append('use_original_name', 'true');
        }
        return this.coreRepository.uploadFile(formData).pipe(
            map((event) => {
                switch (event.type) {
                    case HttpEventType.UploadProgress:
                        // eslint-disable-next-line no-case-declarations
                        const progress = Math.round(100 * event.loaded / event.total);
                        return {status: CoreService.UPLOAD_PROGRESS, message: progress};
                    case HttpEventType.Response:
                        return event.body;
                    default:
                        return `Unhandled event: ${event.type}`;
                }
            })
        );
    };

    // TODO use mainServer if empty prefix
    downloadFile = (path: string, prefix = `${APP_CONFIG.MEDIA}`, target = '_blank'): void => {
        window.open(`${APP_CONFIG.ADMIN_SERVER}${prefix}${path}`, target);
    };

    saveExternalFile(url: string): void
    {
        // Get file name from url.
        const filename = url.substring(url.lastIndexOf("/") + 1).split("?")[0];
        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = function () {
            const a = document.createElement('a');
            a.href = window.URL.createObjectURL(xhr.response); // xhr.response is a blob
            a.download = filename; // Set the file name.
            a.style.display = 'none';
            document.body.appendChild(a);
            a.click();
            // delete a;
        };
        xhr.open('GET', url);
        xhr.send();
    }

    downloadJSON2CSV(items: Record<string, any>[], fileName)
    {
        const replacer = (key, value) => value === null ? '' : value // specify how you want to handle null values here
        const header = Object.keys(items[0])
        const csv = [
            header.join(','), // header row first
            ...items.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','))
        ].join('\r\n')

        const data: Blob = new Blob([csv], {type: 'data:text/csv;charset=utf-8'});
        FileSaver.saveAs(data, fileName + '.csv');
    }

    trackByFn = <T>(index: number, item: T, key: string) => item[key];
}
